import React from 'react';
//import './App.css';


import SocialProof from '../src/components/widgets/SocialProof';
import Features3 from './components/widgets/Features2';
import Content from './components/widgets/Content';
import Pricing from './components/widgets/Pricing';
import Team from './components/widgets/Team';
import {
  contentData,
  featuresData,
  heroData,
  pricingData,
  socialProofData,
} from './shared/data';
import Contact from './components/widgets/Contact';
import { BrowserRouter } from 'react-router-dom';
import Hero2 from './components/widgets/Hero2';


function App() {
  return (
    <BrowserRouter>
      <Hero2 data={heroData} />
      <Features3 {...featuresData} />
      <SocialProof {...socialProofData} />
      <Content {...contentData} />
      <Pricing {...pricingData} />
      <Team />
      <Contact />
    </BrowserRouter>
  );
}

export default App;

import { CallToAction } from '../../shared/types';
import { Link } from 'react-router-dom';
import { AiOutlineWhatsApp } from 'react-icons/ai';




const CTA = (props: { data: CallToAction; class?: string }) => {
  const { text, href, icon: Icon, targetBlank, btnText, btnType } = props.data;

  

  return (
    <>
      {props.data && text && href && (
        <div className="flex w-full sm:w-auto">
          {targetBlank ? (
            <Link
              className={`btn ${btnType === 'primary' ? 'btn-primary' : ''} w-full sm:mb-0 ${
                props.class ? props.class : ''
              } ${btnText === 'uppercase' ? 'uppercase' : ''}`}
              to={"https://web.whatsapp.com/send?phone=+5585999734103"}
              target="_blank"
              rel="noopener noreferrer"
            >
              {Icon && <AiOutlineWhatsApp className="mr-1 -ml-1.5 h-5 w-5" />} {text}
            </Link>
          ) : (
            <Link
              className={`btn ${btnType === 'primary' ? 'btn-primary' : ''} w-full sm:mb-0 ${
                props.class ? props.class : ''
              } ${btnText === 'uppercase' ? 'uppercase' : ''}`}
              to={"https://web.whatsapp.com/send?phone=+5585999734103"}
            >
              {Icon && <AiOutlineWhatsApp className="mr-1 -ml-1.5 h-5 w-5" />} {text}
            </Link>
          )}
        </div>
      )}
    </>
  );
};

export default CTA;

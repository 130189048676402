import { CallToAction, HeroProps } from '../../shared/types';
import CTA from '../common/CTA';

const Hero2 = (props: { data: HeroProps }) => {
  const { title, subtitle, callToAction} = props.data;

  return (
    <section className="mx-auto flex items-center bg-primary-50 dark:bg-slate-800" id="heroTwo">
      <div className="mx-auto flex items-center">
        <div className="block text-center md:flex md:text-center">
          <div className="mx-auto flex items-center">
            <div className="max-w-3xl pb-12 pr-0 md:py-0 md:pr-8 md:pb-0 lg:pr-16">
              {title && (
                <h1 className="leading-tighter font-heading mb-4 px-4 text-5xl font-bold tracking-tighter md:px-0 md:text-[3.48rem]">
                  {title}
                </h1>
              )}
              <div className="mx-auto max-w-3xl">
                {subtitle && <p className="mb-8 text-xl font-normal text-gray-600 dark:text-slate-400">{subtitle}</p>}
                <div className="flex max-w-none flex-col flex-nowrap justify-center gap-4 sm:flex-row md:m-0 md:justify-center">
                  <CTA data={callToAction as CallToAction} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Hero2;

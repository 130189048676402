import { useState } from 'react';
import { FormProps } from '../../shared/types';
import emailjs from '@emailjs/browser';



const Form = ({ title, description, inputs, radioBtns, textarea, checkboxes, btn, btnPosition }: FormProps) => {
    const [name, setName] = useState<any>([]);
    const [email, setEmail] = useState<any>([]);
    const [message, setMessage] = useState('');

    // Update the value of the entry fields
    
    // Update checked radio buttons
   
    // Update the textarea value
    const changeTextareaHandler = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        setMessage(event.target.value);
    };

    // Update checkbox radio buttons
   

    function sendEmail(e: { preventDefault: () => void; }) {
        e.preventDefault();

        if (name === '' || email === '' || message === '') {
            alert("Preencha todos os campos");
            return;
        }

        const templateParams = {
            from_name: name,
            email: email,
            message: message
        }

        emailjs.send('service_uj184y6', 'template_0ncj71n', templateParams, 'eQxdwru3O3gGrUuY5').then((response) => {
            console.log("EMAIL ENVIADO", response.status, response.text);
            setEmail('')
            setName('')
            setMessage('')           
            alert('Email Enviado');
        })

    }

    return (
        <div className="card h-fit max-w-6xl p-5 md:p-12" id="form">
            {title && <h2 className={`${description ? 'mb-2' : 'mb-4'} text-2xl font-bold`}>{title}</h2>}
            {description && <p className="mb-4">{description}</p>}
            <form id="contactForm" onSubmit={sendEmail}>
                <div className="mb-6">
                    {/* Inputs */}
                    <div className="mx-0 mb-1 sm:mb-4">
                        <div key={`item-input-1`} className="mx-0 mb-1 sm:mb-4">
                            <label htmlFor='nome' className="pb-1 text-xs uppercase tracking-wider">
                                {'nome'}
                            </label>
                            <input
                                autoComplete="off"
                                type='text'
                                name='nome'
                                value={name}
                                onChange={(e)=>setName(e.target.value)}
                                placeholder='nome'
                                className="mb-2 w-full rounded-md border border-gray-400 py-2 pl-2 pr-4 shadow-md dark:text-gray-300 sm:mb-0"
                            />
                        </div>
                        <div key={`item-input-2`} className="mx-0 mb-1 sm:mb-4">
                            <label htmlFor='email' className="pb-1 text-xs uppercase tracking-wider">
                                {'email'}
                            </label>
                            <input
                                autoComplete="off"
                                type='email'
                                name='email'
                                value={email}
                                onChange={(e)=>setEmail(e.target.value)}
                                placeholder='email'
                                className="mb-2 w-full rounded-md border border-gray-400 py-2 pl-2 pr-4 shadow-md dark:text-gray-300 sm:mb-0"
                            />
                        </div>
                    </div>

                    {/* Textarea */}
                    {textarea && (
                        <div className={`mx-0 mb-1 sm:mb-4`}>
                            <label htmlFor={textarea.name} className="pb-1 text-xs uppercase tracking-wider">
                                {textarea.label}
                            </label>
                            <textarea
                                name={textarea.name}
                                cols={textarea.cols}
                                rows={textarea.rows}
                                value={message}
                                onChange={(e) => changeTextareaHandler(e)}
                                placeholder={textarea.placeholder}
                                className="mb-2 w-full rounded-md border border-gray-400 py-2 pl-2 pr-4 shadow-md dark:text-gray-300 sm:mb-0"
                            />
                        </div>
                    )}

                </div>
                <div
                    className={`${btnPosition === 'left' ? 'text-left' : btnPosition === 'right' ? 'text-right' : 'text-center'}`}
                >
                    <button type={btn.type} className="btn btn-primary sm:mb-0">
                        {btn.title}
                    </button>
                </div>
            </form>
        </div>
    );
};

export default Form;
